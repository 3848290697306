.link {
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
    color: #000000;
}

.hide {
    display: none;
}

.error-box {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $color-error;
    border: 1px solid $color-error;
    border-radius: 4px;
    font-size: 12px;
    padding: 4px;
}
