/* You can add global styles to this file, and also import other style files */

@import "./assets/styles/fonts.scss";
@import "./assets/styles/sdk.colors.overwrite.scss";
@import "./assets/styles/mat.theme.scss";
@import "./assets/styles/mat.overwrite.scss";
@import "./assets/styles/charts.overwrites.scss";
@import "./assets/styles/loader.scss";
@import "./assets/styles/notify.scss";
@import "./assets/styles/common.scss";
@import "./assets/styles/print.scss";
@import "./assets/styles/inputs.scss";
@import "/src/assets/styles/tables.scss";
@import "./assets/styles/material.icons.scss";
@import "./assets/styles/components.overwrites.scss";

html,
body {
    height: 100%;
    -webkit-text-size-adjust: none;
    overflow-y: hidden !important;
    font-display: block !important;
    box-sizing: border-box;
    color: #65727f;
    font-family: OPPOSans;
    font-size: 14px;
}

::placeholder {
    color: #878787;
    font-family: OPPOSans;
}

h1 {
    font-size: 24px;
}

h2 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
}

h3 {
    font-size: 15px;
    text-transform: uppercase;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.show-sm {
    display: none;
}

@media (max-width: 850px) {
    .hide-sm {
        display: none !important;
    }

    .show-sm {
        display: block !important;
    }
}

@media (max-width: 1365px) {
    .hide-md {
        display: none !important;
    }
}

html,
body {
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    font-family: OPPOSans;
}
