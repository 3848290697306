@import "variables.scss";

.table-parameters {
    text-align: left;
    width: 100%;
    border-collapse: collapse;
    box-sizing: border-box;
    border-spacing: 0;
    border: 1px solid $color-border;

    thead {
        th {
            padding: 16px;
            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            background: $color-lite-grey;
            color: $color-primary;
        }
    }

    td {
        padding: 16px;
        font-weight: 500;
        font-size: 14px;
    }
}

.table {
    border-radius: 4px;
    border: 1px solid $color-border;
    background: #fff;

    thead {
        background-color: $color-content-background;

        th {
            font-size: 14px;
            color: $color-grey;
            font-style: normal;
            font-weight: 400;
        }
    }

    tbody {
        .mat-cell {
            color: $color-black;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }
}
