.input-base {
    border-radius: 4px;
    border: 1px solid $color-border;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    padding: 6px;
    box-sizing: border-box;

    outline: none;
}
