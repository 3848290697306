$color-primary: #ff5000;
$color-secondary: #fb8801;

$color-sidemenu-header: white;
$color-sidemenu-header-btn: black;

$color-content-background: #F8F8F8;


$color-grey: #878787;
$color-lite-grey: #fafafa;
$color-text-grey: #253239;
$color-text-primary: #000000;
$color-dark-grey: #000000;

$color-black: #04263e;

$color-highlight-blue: #f4f9fd;

$color-border: #ebebeb;

$color-success: #46bc41;

$color-blue: #3a4b79;

$color-error: #f84040;

$color-danger: #f19c9c;

$color-white: #ffffff;

$color-black: #000000;

$color-alert: #FF8800;
$color-warning: #FF8800;

$color-bg-hover: #efefef;
