@import "variables.scss";

input {
    &.mat-input-element {
        color: rgba(101, 114, 127, 0.87) !important;
    }
}

.mat-tab-label {
    min-width: 80px !important;
}

// .mat-stroked-button:not(.mat-button-disabled) {
//     border-color: $color-blue;
// }

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: white !important;
}

.mat-slide-toggle .mat-slide-toggle-bar {
    background-color: #000000 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #4dee46 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #4dee46 !important;
}

.mat-checkbox-frame {
    border-color: #ff5000 !important;
}
