@font-face {
    font-family: opposans;
    font-style: normal;
    font-weight: 400;
    src:
        local("OPPOSans"),        
        url(/assets/fonts/opposans//OPPOSansRegular.woff) format("woff");
}
@font-face {
    font-family: opposans;
    font-style: normal;
    font-weight: 400;
    src:
        local("OPPOSans"),
        url(/assets/fonts/opposans//OPPOSansBold.woff) format("woff");
}
@font-face {
    font-family: opposans;
    font-style: normal;
    font-weight: 400;
    src:
        local("OPPOSans"),
        url(/assets/fonts/opposans//OPPOSansHeavy.woff) format("woff");
}
@font-face {
    font-family: opposans;
    font-style: normal;
    font-weight: 400;
    src:
        local("OPPOSans"),
        url(/assets/fonts/opposans//OPPOSansLight.woff) format("woff");
}
@font-face {
    font-family: opposans;
    font-style: normal;
    font-weight: 400;
    src:
        local("OPPOSans"),
        url(/assets/fonts/opposans//OPPOSansMedium.woff) format("woff");
}
